import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import cn from "classnames"
import useResponsive from "../../utils/media-query"
import { useParams, useLocation } from "react-router-dom"
const Navbar = ({ menus }) => {
    const location = useLocation()
    const { slug } = useParams()
    const { pathname } = location
    const { isMobile } = useResponsive()
    const [expandMenuMobile, setExpandMenuMobile] = useState(false)
    const [expandChildMenuMobile, setExpandChildMenuMobile] = useState()
    const navigate = useNavigate()
    const chevronDownPrimary = `${process.env.PUBLIC_URL}/icons/ic_chevron_down_primary.png`
    const chevronDown = `${process.env.PUBLIC_URL}/icons/ic_chevron_down.png`

    const Item = ({ menu }) => {
        const [isHover, setIsHover] = useState(false)
        const data = menu.data
        const child = menu.child
        return (
            <div className="relative">
                <div
                    className="flex flex-row p-[1.25rem] items-center hover:cursor-pointer"
                    onMouseOver={() => {
                        setIsHover(true)
                    }}
                    onMouseOut={() => {
                        setIsHover(false)
                    }}
                    onClick={() => {
                        if (data.slug == "beranda") {
                            navigate("/")
                            setExpandMenuMobile(false)
                        } else if (data.slug == "tentang-kami" || child.length == 0) {
                            if (child.length == 0 && data?.tipe == "LINK") {
                                window.open(data.tipe_id)
                            } else {
                                navigate(`/${data.slug}`)
                            }
                            setExpandMenuMobile(false)
                        }
                    }}
                >
                    <div className={cn("lp-text-subhead-1", isHover ? "text-primary" : "text-black")}>{data.name}</div>
                    {child.length > 0 && <img src={isHover ? chevronDownPrimary : chevronDown} className="ml-[0.2rem] max-w-[1rem] max-h-[1rem]" />}
                </div>
                {isHover && child.length > 0 &&
                    <div className="absolute top-100 flex flex-col bg-white border-2 border-t-primary w-[15rem]"
                        onMouseOver={() => {
                            setIsHover(true)

                        }}
                        onMouseOut={() => {
                            setIsHover(false)
                        }}
                    >
                        {child.map((child, index) => {
                            return (
                                <span key={index} className="lp-text-subhead-1 p-[1rem] hover:text-primary hover:cursor-pointer" onClick={() => {
                                    if (child?.tipe == "LINK") {
                                        window.open(child.tipe_id)
                                    } else {
                                        navigate(`/${child.slug}`)
                                    }
                                    setExpandMenuMobile(false)
                                }}>{child.name}</span>
                            )
                        })}
                    </div>}
            </div>
        )
    }

    return (
        <>
            <div id="navbar" className={cn("z-[99] bg-white w-full flex flex-row items-center justify-between", isMobile ? "p-[1rem]" : "py-[1rem] px-[11rem] ")}>
                <img src={`${process.env.PUBLIC_URL}/icons/ic_logo_main.png`} className={cn(isMobile ? "w-[4.063rem] h-[3.75rem]" : "w-[5.313rem] h-[5.313rem] cursor-pointer")} onClick={() => {
                    navigate("/")
                }} />
                {!isMobile &&
                    <div className="flex flex-row">
                        {menus.length > 0 && menus.map((menu, index) => {
                            return (
                                <Item menu={menu} key={index} />
                            )
                        })}
                    </div>
                }
                {isMobile && <div className="bg-primary w-[2.625rem] h-[2.625rem] rounded-md p-[0.5rem] hover:cursor-pointer" onClick={() => {
                    setExpandMenuMobile(true)
                }}>
                    <img src={`${process.env.PUBLIC_URL}/icons/ic_menu.png`} />
                </div>}
            </div>

            {isMobile && expandMenuMobile && <div className="w-full top-0 fixed h-full bg-black bg-opacity-50 z-[999]">
                <div className="w-[90%] h-full bg-white px-[1rem] py-[2rem] flex flex-col overflow-y-auto right-0 absolute">
                    <img src={`${process.env.PUBLIC_URL}/icons/ic_close.png`} className='w-[1.063rem] h-[1.063rem] self-end mb-[1rem] hover:cursor-pointer' onClick={() => {
                        setExpandMenuMobile(false)
                    }} />
                    <div className='flex flex-col mt-[1.5rem] gap-y-[1rem]'>
                        {menus.length > 0 && menus.map((menu, index) => {
                            const detail = menu.data
                            const child = menu.child
                            const chevronDown = `${process.env.PUBLIC_URL}/icons/ic_chevron_down.png`
                            const chevronDownWhite = `${process.env.PUBLIC_URL}/icons/ic_chevron_down_white.png`
                            const chevronRightPrimary = `${process.env.PUBLIC_URL}/icons/ic_chevron_right_rimary.png`
                            const chevronRight = `${process.env.PUBLIC_URL}/icons/ic_chevron_right.png`
                            const chevronRightWhite = `${process.env.PUBLIC_URL}/icons/ic_chevron_right_white.png`
                            return (
                                <div className="flex flex-col" key={index}>
                                    <div className="flex flex-row justify-between">
                                        <span className={cn("text-[0.938rem] font-semibold", pathname.includes(detail.slug) ? "text-primary" : "text-black")} onClick={() => {
                                            if (detail.slug == "beranda") {
                                                navigate("/")
                                                setExpandMenuMobile(false)
                                            }
                                            else if (detail.slug == "tentang-kami" || child.length == 0) {
                                                if (child.length == 0 && detail?.tipe == "LINK") {
                                                    window.open(detail?.tipe_id)
                                                } else {
                                                    navigate(`/${detail.slug}`)
                                                }
                                                setExpandMenuMobile(false)
                                            }
                                        }}>{detail.name}</span>
                                        {child.length > 0 && <img src={chevronDown} className="w-[1.25rem] h-[1.25rem]" onClick={() => {
                                            if (child.length > 0) {
                                                setExpandChildMenuMobile(index)
                                            } else {
                                                if (detail?.tipe == "LINK") {
                                                    window.open(detail?.tipe_id)
                                                } else {
                                                    navigate(`/${detail.slug}`)
                                                }

                                                setExpandMenuMobile(false)
                                            }
                                        }} />}
                                    </div>

                                    <div className="flex flex-col gap-y-[1rem] mt-[1rem] px-[0.5rem]">
                                        {expandChildMenuMobile && expandChildMenuMobile == index && child.length > 0 && child.map((detailChild, indexChild) => {
                                            return (
                                                <div className="flex flex-row items-center gap-x-[0.5rem]" key={indexChild} onClick={() => {
                                                    if (detailChild?.tipe == "LINK") {
                                                        window.open(detailChild?.tipe_id)
                                                    } else {
                                                        navigate(`/${detailChild.slug}`)
                                                    }
                                                    setExpandMenuMobile(false)
                                                }}>
                                                    <img src={chevronRight} className="w-[0.5rem] h-[0.5rem]" />
                                                    <span className={cn("text-[0.938rem] font-semibold", pathname.includes(detailChild.slug) ? "text-primary" : "text-black")}>{detailChild.name}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Navbar