import Layout from "../../components/layout/Layout"
import { useEffect, useState } from "react"
import DOMPurify from "dompurify"
import moment from "moment"
import { useParams } from "react-router-dom"
import { getContent } from "../../api"
import cn from "classnames"
import useResponsive from "../../utils/media-query"
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";

export default function DetailBerita({ footer, menus }) {
    const { isMobile } = useResponsive()
    const { path, slug } = useParams()
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const fullUrl = window.location.href;

    useEffect(() => {
        setLoading(true)
        getContent(slug)
            .then((response) => {
                const data = response.data.data
                setData(data)
            }).catch((e) => {

            }).finally(() => {
                setLoading(false)
            })
    }, [])

    function formatString(str) {
        return str
            .split('-') // Split the string into an array of words using the hyphen as a delimiter
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '); // Join the array back into a string with spaces
    }

    const Info = ({ icon, text }) => {
        return (
            <div className="flex flex-row items-center gap-x-[0.3rem]">
                <img
                    src={icon}
                    className="w-[0.875rem] h-[0.875rem]" />
                <span className="text-white text-[0.813rem] font-normal">{text}</span>
            </div>
        )
    }

    const Item = ({ data }) => {
        return (
            <div className="flex flex-col gap-y-[1rem] cursor-pointer" onClick={() => {
                window.open(`${process.env.PUBLIC_URL}/berita-kebaikan/${data.slug}`, "_self")
            }}>
                <img src={data.image} className="w-full min-h-[10.125rem] max-h-[10.125rem] rounded-md object-cover" />
                <div className="flex flex-col">
                    <span className="lp-text-body-2 font-semibold text-primary mb-[1rem] line-clamp-2 min-h-[3rem]">{data.name}</span>
                    <span className="lp-text-body-1 line-clamp-4 min-h-[5.25rem] max-h-[5.25rem] mb-[1.5rem]">{data.excerpt}</span>
                    <hr className="border-primary" />
                    <span className="lp-text-caption mt-[0.5rem]">Admin LAZNas</span>
                </div>
            </div>
        )
    }

    return (
        <>
            <Layout footer={footer} menus={menus} noHeadline={true} loading={loading}>
                {data &&
                    <div className="flex flex-col">
                        <div className={cn("w-full flex relative")}>
                            <img src={data.image} className={cn("w-full", isMobile ? "min-h-[15rem] max-h-[15rem] object-cover" : "object-cover min-h-[27.5rem] max-h-[27.5rem]")} />
                            <div className="overlay"></div>
                            <div className={cn("flex flex-col absolute bottom-0 items-center w-full z-[2] text-center ", isMobile ? "px-[1rem] mb-[2rem]" : "mb-[3rem]")}>
                                <div className={cn("text-white font-semibold", isMobile ? "lp-text-title-1 line-clamp-1 mb-[0.5rem]" : "mb-[1rem] text-[1.563rem]")}>{data.name}</div>
                                <div className="flex flex-row gap-x-[1rem] justify-center w-full">
                                    <Info icon={`${process.env.PUBLIC_URL}/icons/ic_circle_user_secondary.png`} text={"Admin Laznas"} />
                                    <Info icon={`${process.env.PUBLIC_URL}/icons/ic_clock_secondary.png`} text={moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format("D MMMM YYYY")} />
                                    <Info icon={`${process.env.PUBLIC_URL}/icons/ic_bookmark_secondary.png`} text={formatString(path)} />
                                </div>
                            </div>
                        </div>
                        <div className={cn("flex flex-col", isMobile ? "px-[1rem] py-[3rem] text-[0.938rem]" : "py-[3rem] px-[23rem]")}>
                            <div className="content-html" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }}>
                            </div>
                        </div>

                        <div className={cn("flex flex-row items-center gap-x-[0.5rem] pb-[3rem]", isMobile ? "px-[1rem]" : "px-[23rem]")}>
                            <span className="text-[0.938rem] font-bold mr-[0.5rem]">Bagikan</span>
                            <FacebookShareButton url={fullUrl}>
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton url={fullUrl}>
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <LinkedinShareButton url={fullUrl}>
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <WhatsappShareButton url={fullUrl}>
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <EmailShareButton url={fullUrl}>
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <TelegramShareButton url={fullUrl}>
                                <TelegramIcon size={32} round />
                            </TelegramShareButton>
                        </div>
                        <hr />
                        <div className={cn("flex flex-col", isMobile ? "px-[1rem] py-[3rem] text-[0.938rem]" : "py-[3rem] px-[23rem]")}>
                            <span className="text-[1.563rem] font-semibold text-black mb-[3rem] text-center">{`Informasi Lainnya`}</span>
                            <div className={cn("grid gap-[2rem]", isMobile ? data.listing.data.length > 1 ? "grid-cols-2" : "grid-cols-1" : "grid-cols-3")}>
                                {data.listing.data.length > 0 && data.listing.data.map((listing, index) => {
                                    return (
                                        <Item data={listing} key={index} />
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                }
            </Layout>
        </>
    )
}