import { useNavigate } from "react-router-dom"
import cn from "classnames"
const LpButton = ({ title, slug, isOutline, icon, align, cta, noicon }) => {
    const navigate = useNavigate()
    return (
        <button
            className={
                cn("btn btn-primary btn-sm mt-[1.5rem] px-[1.5rem] h-[2.5rem] lp-text-button capitalize",
                    isOutline ? "bg-white text-primary hover:bg-slate-200" : "",
                    align)
            }
            onClick={() => {
                if (cta) {
                    window.open(cta)
                }
                if (slug) {
                    navigate(slug)
                }
            }}>
            {title}
            {!noicon && <img src={icon ? icon : `${process.env.PUBLIC_URL}/icons/ic_up_right_white.png`}
                className={icon ? "ml-[0.8rem] w-[1rem] h-[1rem]" : "ml-[0.5rem] mt-[0.1rem] w-[1rem] h-[1rem] "} />}
        </button>
    )
}

export default LpButton