import API from "../utils/api";

const getHome = () => {
    return API.get("home")
}

const getContent = (slug) => {
    return API.get("content", {
        params: {
            "menu_slug": slug
        }
    })
}

const getSearch = (q) => {
    return API.get("search", {
        params: {
            "query": q
        }
    })
}
export { getHome, getContent, getSearch }