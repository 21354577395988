import Layout from "../../components/layout/Layout"
import { useEffect, useState } from "react"
import { getSearch } from "../../api"
import DOMPurify from "dompurify"
import { useParams, useNavigate } from "react-router-dom"
import useResponsive from "../../utils/media-query"
import cn from "classnames"
import { useLocation } from 'react-router-dom';
import LpButton from "../../components/atom/LpButton"

export default function Search({ footer, menus }) {
    const { slug } = useParams()
    const [data, setData] = useState()
    const navigate = useNavigate()
    const { isMobile } = useResponsive()
    const [loading, setLoading] = useState(false)
    const query = useQuery();
    const searchTerm = query.get("s");
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    useEffect(() => {
        setLoading(true)
        getSearch(searchTerm)
            .then((response) => {
                const data = response.data.data
                setData(data)
            }).catch((e) => {

            }).finally(() => {
                setLoading(false)
            })
    }, [slug])

    const Item = ({ data }) => {
        return (
            <div className="flex flex-col">
                <img className="w-full min-h-[42.625rem] max-h-[42.625rem] object-cover" src={data.image} />
                <span className="text-[1.625rem] font-normal mt-[1rem]">{data.name}</span>
                <div className="content-html line-clamp-4 mt-[1rem]" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }} />
                <div className="mt-[1rem]" >
                    <LpButton title={"Lanjut Baca"} slug={`/${data.slug}`} />
                </div>
                <hr className="mt-[3rem]" />
            </div>
        )
    }
    return (
        <Layout footer={footer} menus={menus} loading={loading}>
            <div className={cn("flex flex-col py-[3rem]", isMobile ? "px-[1rem]" : "px-[11rem]")}>
                <span className={cn(isMobile? "text-[1.813rem] font-light text-[#1e293b]" : "text-[2.813rem] font-light text-[#1e293b]")}>Search Result for: {searchTerm}</span>
                <hr className="my-[3rem]" />
                <div className="flex flex-col gap-y-[3rem]">
                    {data && data.page.length > 0 && data.page.map((page, index) => {
                        return (
                            <Item key={index} data={page} />
                        )
                    })}

                    {data && data.post.length > 0 && data.post.map((page, index) => {
                        return (
                            <Item key={index} data={page} />
                        )
                    })}

                    {data && data.publikasi.length > 0 && data.publikasi.map((page, index) => {
                        return (
                            <Item key={index} data={page} />
                        )
                    })}

                    {data && data.portofolio.length > 0 && data.portofolio.map((page, index) => {
                        return (
                            <Item key={index} data={page} />
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
}