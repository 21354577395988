import Layout from "../../components/layout/Layout"
import { useEffect, useState } from "react"
import { getContent } from "../../api"
import DOMPurify from "dompurify"
import moment from "moment"
import LpButton from "../../components/atom/LpButton"
import cn from "classnames"
import useResponsive from "../../utils/media-query"
import { useParams } from "react-router-dom"

export default function BeritaKebaikan({ footer, menus }) {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const { isMobile } = useResponsive()
    const { slug } = useParams()
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 9

    function currentItems() {
        if (data.listing.data.length > 0) {
            return data.listing.data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        }
        else {
            return []
        }
    }

    useEffect(() => {
        setLoading(true)
        getContent(`category/${slug}`)
            .then((response) => {
                const data = response.data.data
                setData(data)
            }).catch((e) => {

            }).finally(() => {
                setLoading(false)
            })
    }, [slug])

    const Info = ({ icon, text }) => {
        return (
            <div className="flex flex-row items-center gap-x-[0.5rem] my-[0.5rem]">
                <img src={icon} className="w-[0.875rem] h-[0.875rem]" />
                <span className="lp-text-caption text-[#96989b] font-normal">
                    {text}
                </span>
            </div>
        )
    }

    const Item = ({ data, title }) => {
        return (
            <div className="w-full flex flex-col">
                <div className="min-h-[18.75rem] max-h-[18.75rem] relative">
                    <img src={data.image} className="rounded-lg w-full h-full object-cover" />
                    <span className="absolute bottom-0 left-0 text-primary bg-white px-[1rem] py-[0.5rem] lp-text-caption font-bold rounded-bl-md">{title}</span>
                </div>
                <span className="mt-[1rem] lp-text-subhead-1 line-clamp-2 font-semibold min-h-[3.5rem]">
                    {data.name}
                </span>
                <Info icon={`${process.env.PUBLIC_URL}/icons/ic_clock_grey.png`} text={moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format("D MMMM YYYY")} />
                <div
                    className="text-[0.938rem] mt-[0.5rem] leading-[1.563rem] text-[#211E1E] font-normal line-clamp-3 content-html"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.description) }}>
                </div>
                <div>
                    <LpButton title={"Lanjut Baca"} slug={`/${slug}/${data.slug}`} />
                </div>
            </div>
        )
    }

    const Button = ({ direction, onClick, disabled }) => {
        const [isHover, setIsHover] = useState(false)

        const chevronRight = `${process.env.PUBLIC_URL}/icons/ic_chevron_right.png`
        const chevronRightWhite = `${process.env.PUBLIC_URL}/icons/ic_chevron_right_white.png`
        const chevronLeft = `${process.env.PUBLIC_URL}/icons/ic_chevron_left.png`
        const chevronLeftWhite = `${process.env.PUBLIC_URL}/icons/ic_chevron_left_white.png`
        return (
            <div
                className={cn("rounded-lg w-[4.188rem] h-[2.188rem] flex justify-center items-center",
                    disabled ? "bg-[#f7f7f7]" : "bg-[#D6D6D6] hover:bg-primary hover:cursor-pointer"
                )}
                onMouseOver={() => {
                    setIsHover(true)
                }}
                onMouseOut={() => {
                    setIsHover(false)
                }}
                onClick={() => {
                    if (!disabled) {
                        onClick()
                        window.scrollTo({
                            top: 210,

                        });
                    }
                }}>
                <img
                    src={direction == "right" ?
                        (isHover && !disabled ? chevronRightWhite : chevronRight) :
                        (isHover && !direction ? chevronLeftWhite : chevronLeft)
                    }
                    className="w-[0.938rem] h-[0.938rem]"
                />
            </div>
        )
    }

    return (
        <>
            {data &&
                <Layout title={data.name} footer={footer} menus={menus} loading={loading}>
                    <div className={cn("flex flex-col", isMobile ? "px-[1rem] py-[3rem]" : "py-[3rem] px-[11rem]")}>
                        <div className={cn("grid grid-cols-1", isMobile ? "gap-y-[3rem]" : "grid-cols-3 gap-x-[1.5rem] gap-y-[3rem] mt-[1.5rem]")}>
                            {currentItems().length > 0 && currentItems().map((listing, key) => {
                                return (
                                    <Item key={key} data={listing} title={data.name} />
                                )
                            })}
                        </div>

                        {data.listing.data.length > 9 &&
                            <div className={cn("flex flex-row gap-x-[0.5rem] justify-center mt-[3rem]")}>
                                <Button direction="left" disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} />
                                <Button direction="right" disabled={currentPage * itemsPerPage >= data.listing.data.length} onClick={() => setCurrentPage(currentPage + 1)} />
                            </div>
                        }
                    </div>
                </Layout>
            }
        </>
    )
}